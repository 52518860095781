import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

API.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  console.log(`Attaching token: ${token}`);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  console.error('Error in request interceptor:', error);
  return Promise.reject(error);
});



export default API;
