import React, {useContext} from 'react';
import {SequenceContext} from "../contexts/SequenceContext";



const SpectroscopicPropertyVis = () => {

  console.log("spectroscopic properties visualization");
  const { selectedSequence } = useContext(SequenceContext);
  if (!selectedSequence.properties) return <p>Cannot render properties (NaN) .</p>;

  const properties = selectedSequence.properties;

  return (
    <div className="spectroscopic-properties">
      <h3>Spectroscopic Properties</h3>
      <p><strong>Is Bistable:</strong> {properties.bistable !== undefined ? (properties.bistable ? 'Yes' : 'No') : 'Not determined'}</p>
      <p><strong>Dark State:</strong> {properties.dark_state || 'Not determined'}</p>
      <p><strong>Light State:</strong> {properties.light_state || 'Not determined'}</p>
      <p><strong>Lambda Max (9-cis):</strong> {properties.lambda_max_9cis ? `${properties.lambda_max_9cis} nm` : 'Not determined'}</p>
      <p><strong>Lambda Max (11-cis):</strong> {properties.lambda_max_11cis ? `${properties.lambda_max_11cis} nm` : 'Not determined'}</p>
      <p><strong>Lambda Max (13-cis):</strong> {properties.lambda_max_13cis ? `${properties.lambda_max_13cis} nm` : 'Not determined'}</p>
      <p><strong>Lambda Max (All-trans):</strong> {properties.lambda_max_alltrans ? `${properties.lambda_max_alltrans} nm` : 'Not determined'}</p>
      <p><strong>Analysis Notes:</strong> {properties.analysisNotes || 'No additional analysis notes.'}</p>
    </div>
  );
};

export default SpectroscopicPropertyVis;
