// Menu.js
import React, { useContext, useEffect } from 'react';
import { SequenceContext } from '../contexts/SequenceContext';

const Menu = () => {
  const { addNewSequence, analyzeSelectedSequence, saveSelectedSequence, loadSequence, selectedSequence, resetData,
    sequenceSummaries, loadSequenceSummaries, loadingSequenceSummaries  } = useContext(SequenceContext);

  useEffect(() => {
    console.log("Menu component mounted, loading sequence summaries...",  );
    loadSequenceSummaries();
  }, []);

  const handleNewSequenceClick = () => {
    addNewSequence();
  };

  const handleAnalyzeSequenceClick = () => {
    console.log('Analyzing sequence (pressed in menu)');
    analyzeSelectedSequence();
  };

  const handleSaveSequenceClick = async () => {
    if (selectedSequence) {
      try {
        // Destructure to exclude isTemporary and any other non-required properties
        const { isTemporary, tempId, ...sequenceData } = selectedSequence;
        // Assume saveSelectedSequence already handles whether to POST or PUT based on isTemporary
        await saveSelectedSequence(); // token needs to be retrieved from auth context or similar

        // Optionally, trigger a state refresh or re-fetch sequences to reflect the new state
        // This could involve re-selecting the saved sequence by its new permanent ID, if necessary
      } catch (error) {
        console.error("Failed to save sequence:", error);
        // Provide user feedback on failure
      }
    }
  };

  const handleResetDataClick = () => {
    resetData();
  };


  const handleSequenceSelect = async (id) => {
    // Assume there's a function in your context to load a sequence by its ID
    await loadSequence(id);
  };

  const uploadSequence = () => {

  }

  const uploadSequences = () => {

  }

  const loadData = () => {

  }

  const deleteSequence = () => {

  }

  // dropdown menu for sequences to select any sequence from the list
  const selectSequence = () => {

  }
  return (
    <nav className="dashboard-sidebar">
      <ul>
        <li><button onClick={handleNewSequenceClick}>New</button></li>
        <li><button onClick={handleAnalyzeSequenceClick}>Analyze</button></li>
        <li><button onClick={handleSaveSequenceClick}>Save</button></li>
        <li><button onClick={handleResetDataClick}>Reset</button></li>


        {loadingSequenceSummaries ? (
          <li>Loading sequences...</li>
        ) : (
          <li>
            <select onChange={(e) => handleSequenceSelect(e.target.value)}
                    value={selectedSequence?.id || ''}>
              <option>Select a Sequence</option>
              {sequenceSummaries?.map((sequence) => (
                <option key={sequence.id} value={sequence.id}>
                  {sequence.name}
                </option>
              ))}
            </select>
          </li>
        )}

        {/* Additional menu options */}
      </ul>
    </nav>
  );
};

export default Menu;

