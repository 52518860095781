import React from 'react';
import { UserProvider } from '../contexts/UserContext';
import { SequenceProvider } from '../contexts/SequenceContext';
export const ContextProviders = ({ children }) => {
  return (
    <UserProvider>
        <SequenceProvider>
            {children}
        </SequenceProvider>
    </UserProvider>
  );
};
