import React, { createContext, useContext, useState } from 'react';
import { postProteinSequence, getPrivateSequences, getPublicSequences, saveProteinSequence } from "../utils/APICalls";
import { createProteinSequence, updateProteinSequence, loadProteinSequence } from "../utils/APICalls";
import isEqual from 'lodash.isequal';


export const SequenceContext = createContext();

export const SequenceProvider = ({ children }) => {
  const [sequences, setSequences] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [hasAnalysisResults, setHasAnalysisResults] = useState(false);
  const [sequenceSummaries, setSequenceSummaries] = useState(null);
  const [loadingSequenceSummaries, setLoadingSequenceSummaries] = useState(true);


  const selectSequence = (sequenceId) => {
    const sequence = sequences.find(seq => seq.id === sequenceId);
    setSelectedSequence(sequence);
    setHasAnalysisResults(sequence && sequence.properties && Object.keys(sequence.properties).length > 0);
  };

  // upon changing something about the selected sequence we can update
  const updateSequence = (sequenceId, updatedSequence) => {
    setSequences((prevSequences) => prevSequences.map(seq => seq.id === sequenceId ? { ...seq, ...updatedSequence } : seq));
    if (selectedSequence && selectedSequence.id === sequenceId) {
      setSelectedSequence(prevSelected => ({ ...prevSelected, ...updatedSequence }));
    }
  };

  // the properties of the sequence are updated, properties are stored in 'properties' object
  const updateSequenceProperties = (sequenceId, updatedProperties) => {
    setSequences((prevSequences) => prevSequences.map(seq => {
      if (seq.id === sequenceId) {
        return { ...seq, properties: { ...seq.properties, ...updatedProperties }};
      }
      return seq;
    }));

    if (selectedSequence && selectedSequence.id === sequenceId) {
      setSelectedSequence(prevSelected => ({ ...prevSelected, properties: { ...prevSelected.properties, ...updatedProperties }}));
    }
  };

  // the sequnece text is updated, i.e. the protein sequence, e.g., 'MAGLS....'
  const updateSequenceText = (sequenceId, newSequenceText) => {
    setSequences((prevSequences) => prevSequences.map(seq => {
      if (seq.id === sequenceId) {
        return { ...seq, sequence: newSequenceText };
      }
      return seq;
    }));

    if (selectedSequence && selectedSequence.id === sequenceId) {
      setSelectedSequence(prevSelected => ({ ...prevSelected, sequence: newSequenceText }));
    }
  };

  // Analyzing a sequence
  const analyzeSelectedSequence = async () => {
    if (!selectedSequence) {
      console.error('No sequence selected for analysis');
      return;
    }

    setIsAnalyzing(true);
    try {
      const analysisResults = await postProteinSequence({ sequence: selectedSequence.sequence });
      // Assuming analysis results are formatted as needed by the frontend
      updateSequenceProperties(selectedSequence.id, analysisResults);
      setHasAnalysisResults(true);
    } catch (error) {
      console.error('Error analyzing sequence:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };


  const archiveSelectedSequence = () => {
    if (selectedSequence && !selectedSequence.isTemporary) {
      // Check that the sequence isn't already archived
      if (!sequences.some(seq => seq.id === selectedSequence.id)) {
        setSequences(prev => [...prev, selectedSequence]);
      }
      setSelectedSequence(null); // Clear the currently selected sequence
      setHasAnalysisResults(false);
    } else {
      // Handle temporary sequences: discard or ask the user
      console.log('Temporary sequence not archived');
    }
  };

  const clearTemporarySequence = () => {
    if (selectedSequence && selectedSequence.isTemporary) {
      setSelectedSequence(null);
      setHasAnalysisResults(false);
    }
  }

  // Initialize a new sequence (temporary) for user input
  const addNewSequence = () => {
    // archiveSelectedSequence(); // Optional: Archive the current sequence before adding a new one

    const tempSequence = {
      id: `temp-${Date.now()}`, // Unique temporary ID
      sequence: '', // Start with an empty sequence
      properties: {}, // Placeholder for additional properties
      isTemporary: true, // Mark as temporary
    };

    setSelectedSequence(tempSequence); // Set as the currently selected sequence
    setIsAnalyzing(false); // Reset analyzing state
    setHasAnalysisResults(false); // Reset analysis results
  };

  // helper to format the sequence data before sending it to the backend
  const prepareSequenceDataForSave = (sequence) => {
    // Define model properties - only include these in the request
    const modelProperties = [
      'sequence_type',
      'species',
      'bistable',
      'dark_state',
      'light_state',
      'lambda_max_9cis',
      'lambda_max_11cis',
      'lambda_max_13cis',
      'lambda_max_alltrans',
      'visibility', // Assuming visibility is part of your model and should be included
    ];

    // Initialize an empty object for the prepared sequence data
    let preparedData = {};

    // Loop through model properties to build the prepared data object
    modelProperties.forEach(prop => {
      if (sequence.properties.hasOwnProperty(prop)) {
        // If property exists in sequence, use it; otherwise set to null if it's optional
        preparedData[prop] = sequence.properties[prop] !== undefined ? sequence.properties[prop] : null;
      }
    });

    // Special handling for properties that must not be null or need default values
    // For example, if 'visibility' is mandatory and should default to 'private'
    preparedData.name = sequence.name || 'Untitled Sequence';
    preparedData.sequence = sequence.sequence || '';
    preparedData.visibility = preparedData.visibility || 'private';

    return preparedData;
  };


  const saveSelectedSequence = async () => {
    if (!selectedSequence) {
      console.error('No sequence selected to save');
      return;
    }
    console.log(selectedSequence);
    console.log(selectedSequence.properties);

    try {
      const sequenceData = prepareSequenceDataForSave(selectedSequence);
      let savedSequence;

      if (selectedSequence.isTemporary) {
        savedSequence = await createProteinSequence(sequenceData);
      } else {
        savedSequence = await updateProteinSequence(selectedSequence.id, sequenceData);
      }

      updateSequenceState(savedSequence); // Assuming updateSequenceState properly updates all related states
      loadSequenceSummaries(); // Refresh sequence summaries
    } catch (error) {
      console.error('Error saving/updating sequence:', error);
    }
  };

  const resetData = () => {
    setSequences([]);
    setSelectedSequence(null);
    setIsAnalyzing(false);
    setHasAnalysisResults(false);
    loadSequenceSummaries(); // Refresh sequence summaries
  };

    // gets all available public sequences (their ids)
  const loadPublicSequences = async () => {
    try {
      const data = await getPublicSequences();
      setSequences(data);
    } catch (error) {
      console.error('Error fetching public sequences:', error);
    }
  };

  // gets all available private sequences (their ids)
  const loadPrivateSequences = async (token) => {
    try {
      const data = await getPrivateSequences(token);
      setSequences(data);
    } catch (error) {
      console.error('Error fetching private sequences:', error);
    }
  };

  const loadSequenceSummaries = async () => {
    try {
      setLoadingSequenceSummaries(true); // Start loading
      const token = localStorage.getItem('token');
      const privateSequences = await getPrivateSequences(token);
      const publicSequences = await getPublicSequences();
      const combinedSequences = [...privateSequences, ...publicSequences].map(seq => ({
        id: seq.id,
        name: seq.name,
      }));
      setSequenceSummaries(combinedSequences); // Cache the combined sequence
      setLoadingSequenceSummaries(false); // Data is loaded
    } catch (error) {
      console.error('Error fetching sequences summaries:', error);
      setLoadingSequenceSummaries(false); // Data failed to load
    }
  };

  const updateSequenceState = (sequence) => {
    // Assuming sequence is the complete sequence object received from the backend
    setSelectedSequence(sequence);
    setIsAnalyzing(false); // Reset analyzing state since we are loading a sequence

    // Set hasAnalysisResults based on whether the sequence has properties
    const hasResults = sequence && sequence.properties && Object.keys(sequence.properties).length > 0;
    setHasAnalysisResults(hasResults);

    // Here you might also set other states relevant to the loaded sequence. For example:
    // If the sequence was temporary and is now being saved, you might want to update the sequences list.
    if (sequence.isTemporary) {
      setSequences(prevSequences => [...prevSequences, sequence]);
    }

    // Ensure that isTemporary is updated based on whether the loaded sequence has a permanent ID
    if (sequence.id && sequence.id.toString().startsWith('temp-')) {
      setSelectedSequence(prev => ({ ...prev, isTemporary: true }));
    } else {
      setSelectedSequence(prev => ({ ...prev, isTemporary: false }));
    }
  };

  // our backend dataformat is flat and not the same as the frontend, so we need a helper
  const transformSequenceData = (sequenceData) => {
    const { id, name, sequence, ...rest } = sequenceData;

    // All remaining properties are considered part of 'properties'
    const properties = { ...rest };

    return {
      id,
      name,
      sequence,
      properties, // Nest additional details under 'properties'
    };
  };

  // only for now use this, we want all api calls in the APICalls.js file, note that the token is loaded in AxiosInstance.js

  const loadSequence = async (id) => {
    try {
      console.log('Loading sequence:', id);
      const data = await loadProteinSequence(id); // This should be implemented in APICalls.js to use the Axios instance
      console.log(data);
      const transformedData = transformSequenceData(data);
      updateSequenceState(transformedData); // Use the helper function to update state
    } catch (error) {
      console.error('Error loading sequence:', error);
    }
  };

  return (
    <SequenceContext.Provider value={{
      sequences,
      selectedSequence,
      isAnalyzing,
      hasAnalysisResults,
      sequenceSummaries,
      loadPublicSequences,
      loadPrivateSequences,
      loadSequenceSummaries,
      loadSequence,
      analyzeSelectedSequence,
      selectSequence,
      updateSequence,
      updateSequenceProperties,
      updateSequenceText,
      archiveSelectedSequence,
      clearTemporarySequence,
      addNewSequence,
      saveSelectedSequence,
      resetData
    }}>
      {children}
    </SequenceContext.Provider>
  );
};

export const useSequences = () => useContext(SequenceContext);
