import React, { useContext } from 'react';
import { SequenceContext } from '../contexts/SequenceContext';

const SequenceDisplay = () => {
  const { selectedSequence } = useContext(SequenceContext);

  // Check if there's a selected sequence available
  if (!selectedSequence) {
    return <div>No sequence selected.</div>;
  }

  // Render the selected sequence's details
  return (
    <div className="sequence-display">
      <h3>Sequence Details</h3>
      <p><strong>ID:</strong> {selectedSequence.id}</p>
      <p><strong>Sequence:</strong> {selectedSequence.sequence}</p>
      {/* Render more details as needed */}
    </div>
  );
};

export default SequenceDisplay;
