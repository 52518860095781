import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ContextProviders } from "./contexts/ContextProviders";

import PrivateRouteWrapper from "./utils/PrivateRoute";
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import HomePage from "./pages/HomePage";

function App() {
  return (
    <Router>
      <ContextProviders>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          {/* Wrap the HomePage route in the PrivateRouteWrapper to make it a protected route */}
          <Route path="/" element={
            <PrivateRouteWrapper>
              <HomePage />
            </PrivateRouteWrapper>
          } />
        </Routes>
      </ContextProviders>
    </Router>
  );
}

export default App;
