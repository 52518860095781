import API from './AxiosInstance';
import axios from 'axios';

// Handles user login
export const loginUser = async (username, password) => {
  const response = await API.post('users/login/', { username, password });
  return response.data;
};

// Handles user logout
export const logoutUser = async () => {
  const response = await API.post('users/logout/');
  localStorage.removeItem('access_token');
  return response.data;
};

// Handles user registration without sending an authorization token
export const registerUser = async (data) => {
  // Directly use axios here instead of the API instance with the interceptor
  const baseURL = process.env.REACT_APP_BACKEND_URL;
  const response = await axios.post(`${baseURL}users/register/`, data);
  return response.data;
};

// Checks user authentication status
export const checkAuth = async () => {
  try {
    const response = await API.get('users/profile/');
    return response.data;
  } catch (error) {
    console.error('Error checking authentication:', error);
    throw error;
  }
};

export const getPublicSequences = async () => {
  try {
    const response = await API.get('sequences/public/');
    return response.data;
  } catch (error) {
    console.error('Error getting public sequences:', error);
    throw error;
  }
};

export const getPrivateSequences = async () => {
  try {
    const response = await API.get('sequences/private/');
    return response.data;
  } catch (error) {
    console.error('Error getting private sequences:', error);
    throw error;
  }
};

export const postProteinSequence = async (sequenceData) => {
  console.log(localStorage.getItem('access_token'));

  try {
    const response = await API.post('sequences/submit/', sequenceData);
    return response.data;
  } catch (error) {
    console.error('Error posting protein sequence:', error);
    throw error;
  }
};

// For creating a new sequence
export const createProteinSequence = async (sequenceData) => {
  try {
    const response = await API.post('sequences/save/', sequenceData);
    return response.data;
  } catch (error) {
    console.error('Error creating protein sequence:', error);
    throw error;
  }
};

// For updating an existing sequence
export const updateProteinSequence = async (sequenceId, sequenceData) => {
  try {
    const response = await API.put(`sequences/update/${sequenceId}/`, sequenceData);
    return response.data;
  } catch (error) {
    console.error('Error updating protein sequence:', error);
    throw error;
  }

};
export const loadProteinSequence = async (sequenceId) => {
  try {
    console.log("calling loadProteinSequence with sequenceId:", sequenceId);
    const response = await API.get(`sequences/load/${sequenceId}/`);
    console.log("API response data:", response.data);  // Check what's returned
    return response.data;
  } catch (error) {
    console.error('Error loading protein sequence:', error);
    throw error;
  }
};
