import React, { useContext } from 'react';
import { SequenceContext } from '../contexts/SequenceContext';

const SequenceInputForm = () => {
  const { updateSequenceText, selectedSequence } = useContext(SequenceContext);

  const handleSequenceChange = (event) => {
    // Pass the new sequence text to updateSequenceText
    console.log("sequence input form calling update on new seq:", event.target.value);
    updateSequenceText(selectedSequence.id, event.target.value);
    console.log("selected sequence after update:", selectedSequence);
  };

  return (
    <input
      type="text"
      onChange={handleSequenceChange}
      value={selectedSequence ? selectedSequence.sequence : ''}
      placeholder="Enter protein sequence"
    />
  );
};

export default SequenceInputForm;
