import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../utils/APICalls';
import { UserContext } from '../contexts/UserContext';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(UserContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await loginUser(username, password);
      login(data); // Update the user context with the returned data
      navigate('/');
    } catch (error) {
      setError('Invalid username or password');
    }
  };

  return (
    <div>
      <h1>Welcome to my app!</h1>
      <div>
        <h2>Sign in</h2>
        <form onSubmit={handleLogin}>
          <label>
            Username:
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </label>
          <label>
            Password:
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </label>
          <button type="submit">Login</button>
        </form>
        <p>
          Don't have an account? <a href="/signup" onClick={() => setError('')}>Sign up here</a>.
        </p>
      </div>
      {error && <p>{error}</p>}
    </div>
  );
}

export default LoginPage;
