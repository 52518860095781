import React, { useContext } from 'react';
import { SequenceContext } from '../contexts/SequenceContext';
import SequenceInputForm from '../forms/SequenceInputForm';
import SpectroscopicPropertyVis from '../components/SpectroscopicPropertyVis';
import LoadingBar from '../components/LoadingBar';
import SequenceDisplay from "../components/SequenceDisplay";

const SequenceAnalysisContainer = () => {
  const {
    isAnalyzing,
    hasAnalysisResults
  } = useContext(SequenceContext);

  return (
    <div className="sequence-analysis-container">

      {!hasAnalysisResults && <SequenceInputForm/>}

      {isAnalyzing && <LoadingBar />}

      {hasAnalysisResults && <SequenceDisplay />}

      {hasAnalysisResults && <SpectroscopicPropertyVis />}
    </div>
  );
};

export default SequenceAnalysisContainer;
