import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../utils/APICalls';



function SignupPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    const userData = {
      username,
      password1: password,
      password2,
      email,
      first_name: firstName,
      last_name: lastName,
    };
    try {
      await registerUser(userData);
      navigate('/login', { state: { registrationSuccess: true } });
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data)
          .map(val => Array.isArray(val) ? val[0] : val)
          .join(' ');
        setError(errorMessages);
      } else {
        setError('An error occurred, please try again later');
      }
    }
  };


  return (
    <div>
      <h1>Sign up</h1>
      <form onSubmit={handleSignup}>
        <label style={{ display: 'block' }}>
          First Name:
          <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </label>
        <label style={{ display: 'block' }}>
          Last Name:
          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </label>
        <label style={{ display: 'block' }}>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <label style={{ display: 'block' }}>
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <label style={{ display: 'block' }}>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <label style={{ display: 'block' }}>
          Confirm Password:
          <input type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
        </label>
        <button type="submit">Sign up</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
}

export default SignupPage;
