import Menu from '../components/Menu';
import SequenceAnalysisContainer from "../containers/SequenceAnalysisContainer";

const HomePage = () => {


  return (
    <div className="dashboard">
      <Menu />
      <SequenceAnalysisContainer />
    </div>
  );
};

export default HomePage;
