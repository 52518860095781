import { useNavigate, useLocation } from 'react-router-dom';
import useUser from '../hooks/useUser';
import { Navigate } from 'react-router-dom'; // Import Navigate

function PrivateRouteWrapper({ children }) {
  const { user } = useUser();
  const location = useLocation();

  if (!user) {
    // Use the Navigate component for redirection
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default PrivateRouteWrapper;
